/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNavItem = /* GraphQL */ `
  query GetNavItem($id: ID!) {
    getNavItem(id: $id) {
      id
      name
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNavItems = /* GraphQL */ `
  query ListNavItems(
    $filter: ModelNavItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNavItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        url
        content
        coverImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDraftPage = /* GraphQL */ `
  query GetDraftPage($id: ID!) {
    getDraftPage(id: $id) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDraftPages = /* GraphQL */ `
  query ListDraftPages(
    $filter: ModelDraftPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDraftPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        url
        content
        coverImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
