import "./Contact.scss";
// import ContactForm from "../../Components/Forms/Contact/Contact";
// import BrevoContact from "../../Components/Forms/Brevo/BrevoContact";

const Contact = () => {
  return (
    <div className="contact">
      {/* <div className="container">
        <h1>Contact Us</h1> */}
      {/* <ContactForm /> */}
      {/* <BrevoContact /> */}
      <div className="iframe-container">
        {/* <iframe
          title="contain-form"
          width="375"
          height="1250"
          src="https://ee04d834.sibforms.com/serve/MUIFAPwxoZJfvgrAj4xh6qJInZZULh2Cheof7WYstVR01aL9N-H09TghIH1hORbs0DKjDD7JGyM1P9XYeWcrOOw1ZaOwEnIt9mQaIbNy0QkQsSEruiDzw9uThXZ6OP_AHeBFucMfJVCQ9sTUA913D4Lm0CX85YAo_HeLV8rJKvzEyzfz4I8F0TFokg3iCsTWEeHfDiQwRwiSMPi6"
          frameborder="0"
          scrolling="auto"
          allowfullscreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        ></iframe> */}
        <iframe
          title="contact-form"
          width="375"
          height="1250"
          src="https://ee04d834.sibforms.com/serve/MUIFAHD-7ORh1tBVp60uFA8PYQl-n4W2InMSE0wi18W4qdc-7exw7HoMJRoY9I8VKTQGhWfWiozMF0uTPvmegcHnBIDuKnKOH56VgyufO9OOLuvo0BJtweE6u_sz4TUgZht36vaLUL_8fO8Qc9jDBy8_h-RCuZRxBXgj_4SMLFiqm8bFtRVq_7HKg2W0ovWV9D4qBwNJI84qffBB"
          frameborder="0"
          scrolling="auto"
          allowfullscreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        ></iframe>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Contact;
