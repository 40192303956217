import React, { useRef, useEffect, useState } from "react";
import "./WysiwygEditor.scss";

const WysiwygEditor = ({ initialContent, onContentChange }) => {
  const editorRef = useRef(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const savedSelection = useRef(null);

  useEffect(() => {
    if (
      editorRef.current &&
      initialContent &&
      editorRef.current.innerHTML === ""
    ) {
      editorRef.current.innerHTML = initialContent;
      addResizingListenersToExistingElements();
      onContentChange(initialContent);
    }
  }, [initialContent]);

  const execCmd = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const addImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = document.createElement("img");
          img.src = e.target.result;
          img.draggable = false;

          img.onload = () => {
            img.style.width = `${img.naturalWidth}px`;
            img.style.height = `${img.naturalHeight}px`;
            makeElementResizable(img, img.naturalWidth / img.naturalHeight);
            insertElementAtCaret(img);
          };
        };
        reader.readAsDataURL(file);
      }
    };
  };

  const openVideoModal = () => {
    saveSelection();
    setVideoUrl(""); // Clear previous URL
    setIsVideoModalOpen(true);
  };

  const handleInsertVideo = () => {
    const embedUrl = convertToEmbedUrl(videoUrl);
    if (embedUrl) {
      restoreSelection();
      const wrapper = document.createElement("div");
      wrapper.classList.add("video-wrapper");
      wrapper.contentEditable = "false";

      const iframe = document.createElement("iframe");
      iframe.src = embedUrl;
      iframe.width = "560";
      iframe.height = "315";
      iframe.frameBorder = "0";
      iframe.allow =
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
      iframe.allowFullscreen = true;

      wrapper.appendChild(iframe);
      makeElementResizable(wrapper, 560 / 315);
      insertElementAtCaret(wrapper);

      setIsVideoModalOpen(false); // Close modal
      updateContent(); // Update editor content
    } else {
      alert("Invalid video URL. Please enter a valid YouTube or Vimeo URL.");
    }
  };

  const convertToEmbedUrl = (url) => {
    if (url.includes("youtu.be") || url.includes("youtube.com")) {
      const videoId = url
        .split(/(v=|\/|embed\/|youtu.be\/|\/v\/|watch\?v=|&v=)/)
        .filter(Boolean)
        .pop();
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes("vimeo.com")) {
      const videoId = url.split("/").pop();
      return `https://player.vimeo.com/video/${videoId}`;
    }
    return null;
  };

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    if (savedSelection.current) {
      selection.removeAllRanges();
      selection.addRange(savedSelection.current);
    }
  };

  const makeElementResizable = (element, aspectRatio) => {
    let originalWidth, originalHeight, originalMouseX, originalMouseY;

    element.addEventListener("mousedown", (e) => {
      e.preventDefault();
      originalWidth = element.offsetWidth;
      originalHeight = element.offsetHeight;
      originalMouseX = e.pageX;
      originalMouseY = e.pageY;

      window.addEventListener("mousemove", startResize);
      window.addEventListener("mouseup", stopResize);
    });

    function startResize(e) {
      const newWidth = originalWidth + (e.pageX - originalMouseX);
      const newHeight = newWidth / aspectRatio;
      element.style.width = `${newWidth}px`;
      element.style.height = `${newHeight}px`;
      onContentChange(editorRef.current.innerHTML);
    }

    function stopResize() {
      window.removeEventListener("mousemove", startResize);
      window.removeEventListener("mouseup", stopResize);
    }
  };

  const addResizingListenersToExistingElements = () => {
    const images = editorRef.current.querySelectorAll("img");
    images.forEach((img) => {
      if (!img.hasAttribute("resizable")) {
        img.setAttribute("resizable", "true");
        makeElementResizable(img, img.naturalWidth / img.naturalHeight);
      }
    });

    const videos = editorRef.current.querySelectorAll(".video-wrapper");
    videos.forEach((videoWrapper) => {
      if (!videoWrapper.hasAttribute("resizable")) {
        videoWrapper.setAttribute("resizable", "true");
        makeElementResizable(videoWrapper, 560 / 315);
      }
    });
  };

  const insertElementAtCaret = (element) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(element);

    range.setStartAfter(element);
    range.setEndAfter(element);
    selection.removeAllRanges();
    selection.addRange(range);

    updateContent();
  };

  const updateContent = () => {
    if (editorRef.current) {
      onContentChange(editorRef.current.innerHTML);
    }
  };

  return (
    <div className="editor-container">
      <h2>Custom WYSIWYG Editor</h2>
      <div className="toolbar">
        <button onClick={() => execCmd("bold")}>
          <b>B</b>
        </button>
        <button onClick={() => execCmd("italic")}>
          <i>I</i>
        </button>
        <button onClick={() => execCmd("underline")}>
          <u>U</u>
        </button>
        <button onClick={() => execCmd("insertOrderedList")}>OL</button>
        <button onClick={() => execCmd("insertUnorderedList")}>UL</button>
        <button
          onClick={() => execCmd("createLink", prompt("Enter URL", "http://"))}
        >
          Link
        </button>
        <button onClick={() => execCmd("unlink")}>Unlink</button>
        <button onClick={addImage}>Upload Image</button>
        <button onClick={openVideoModal}>Embed Video</button>
        <button onClick={() => execCmd("justifyLeft")}>Left</button>
        <button onClick={() => execCmd("justifyCenter")}>Center</button>
        <button onClick={() => execCmd("justifyRight")}>Right</button>
        <button onClick={() => execCmd("undo")}>Undo</button>
        <button onClick={() => execCmd("redo")}>Redo</button>
      </div>

      <div
        ref={editorRef}
        className="editor"
        contentEditable="true"
        onInput={updateContent}
      ></div>

      {isVideoModalOpen && (
        <div className="video-modal">
          <div className="video-modal-content">
            <h3>Embed Video</h3>
            <input
              type="text"
              placeholder="Enter YouTube or Vimeo URL"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
            />
            <button onClick={handleInsertVideo}>Insert</button>
            <button onClick={() => setIsVideoModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WysiwygEditor;
