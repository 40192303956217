import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import imageCompression from "browser-image-compression";
import { listPages } from "../graphql/queries";
import { createPage } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import "./Admin.scss";
import WysiwygEditor from "../Components/WysiwygEditor/WysiwygEditor";

const AddPage = () => {
  const client = generateClient();
  const [newPage, setNewPage] = useState({
    title: "",
    url: "",
    content: "",
    coverImage: "",
  });
  const [file, setFile] = useState(null); // State to hold selected file
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const apiData = await client.graphql({ query: listPages });
      setPages(apiData.data.listPages.items);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const slugify = (title) => {
    return `/blog/${title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-")}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      const formattedUrl = slugify(value);
      setNewPage({ ...newPage, title: value, url: formattedUrl });
    } else {
      setNewPage({ ...newPage, [name]: value });
    }
  };

  const handleContentChange = (content) => {
    setNewPage({ ...newPage, content });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Function to handle image upload and get URL
  const uploadImage = async () => {
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.4,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const base64Image = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
        return base64Image;
      } catch (error) {
        console.error("Error uploading cover image:", error);
        setError("Error uploading cover image. Please try again.");
        return null;
      }
    }
    return null;
  };

  const isDuplicateUrl = (url) => {
    return pages.some((page) => page.url === url);
  };

  const handleCreatePage = async () => {
    if (!newPage.title || !newPage.content) {
      setError("Please fill out all fields.");
      return;
    }

    if (isDuplicateUrl(newPage.url)) {
      setError(
        "A page with this URL already exists. Please choose a different title."
      );
      return;
    }

    try {
      setIsSubmitting(true);

      // Step 1: Upload image and get URL
      const imageUrl = await uploadImage();
      if (imageUrl) {
        setNewPage((prev) => ({ ...prev, coverImage: imageUrl }));
      }

      // Step 2: Create page data
      const pageData = {
        ...newPage,
        coverImage: imageUrl, // Include image URL after uploading
      };

      await client.graphql({
        query: createPage,
        variables: { input: pageData },
        authMode: "userPool",
      });
      setSuccess("Page created successfully.");
      setError("");

      setTimeout(() => {
        navigate("/my-admin");
      }, 2000);
    } catch (error) {
      console.error("Error creating page:", error);
      setError("Error creating page. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="admin-page">
      <h1>Create New Page</h1>
      <div className="create-page">
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        <input
          type="text"
          name="title"
          placeholder="Page Title"
          value={newPage.title}
          onChange={handleChange}
        />
        <input
          type="text"
          name="url"
          placeholder="Page URL"
          value={newPage.url}
          readOnly
        />

        {/* Cover image upload input */}
        <input type="file" accept="image/*" onChange={handleFileChange} />
        {newPage.coverImage && (
          <img
            src={newPage.coverImage}
            alt="Cover Preview"
            style={{ width: "200px", marginTop: "10px", display: "block" }}
          />
        )}

        <WysiwygEditor onContentChange={handleContentChange} />

        <button onClick={handleCreatePage} disabled={isSubmitting}>
          {isSubmitting ? "Creating..." : "Create Page"}
        </button>
      </div>
    </div>
  );
};

export default AddPage;
