import React, { useEffect } from "react";
import "./ProgressBar.scss";

const ProgressBar = () => {
  useEffect(() => {
    const progressBars = document.querySelectorAll(".progress-bar-fill");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const percent = entry.target.getAttribute("data-percent");
            entry.target.style.width = `${percent}%`;
          } else {
            // Reset the width when the element is out of view
            entry.target.style.width = `0%`;
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the progress bar is visible
    );

    progressBars.forEach((bar) => {
      observer.observe(bar);
    });

    return () => {
      progressBars.forEach((bar) => {
        observer.unobserve(bar);
      });
    };
  }, []);

  return (
    <div className="performance-section">
      <div className="performance-bar">
        <p>Performance</p>
        <div className="progress-bar">
          <div className="progress-bar-fill" data-percent="100"></div>
        </div>
      </div>

      <div className="performance-bar">
        <p>Accessibility</p>
        <div className="progress-bar">
          <div className="progress-bar-fill" data-percent="100"></div>
        </div>
      </div>

      <div className="performance-bar">
        <p>Best Practices</p>
        <div className="progress-bar">
          <div className="progress-bar-fill" data-percent="100"></div>
        </div>
      </div>

      <div className="performance-bar">
        <p>Search Engine Optimisation</p>
        <div className="progress-bar">
          <div className="progress-bar-fill" data-percent="100"></div>
        </div>
      </div>

      {/* <div className="performance-bar bottom">
        <div className="progress-bar">
          <div className="progress-bar-fill" data-percent="100"></div>
        </div>
        <h2>Unparalleled Speed and Performance.</h2>
        <p className="text-under-heading">
          The best platform to increase your website's Google search rankings.
        </p>
      </div> */}
    </div>
  );
};

export default ProgressBar;
