import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { getPage } from "../graphql/queries";
import { updatePage } from "../graphql/mutations";
import { useParams, useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression"; // Import image compression library
import WysiwygEditor from "../Components/WysiwygEditor/WysiwygEditor";
import "./Admin.scss";

const EditPage = () => {
  const client = generateClient();
  const { id } = useParams();
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    id: "",
    title: "",
    url: "",
    content: "",
    coverImage: "", // Field for the compressed cover image
  });
  const [file, setFile] = useState(null); // Store file for cover image
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const slugify = (title) => {
    return `/blog/${title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-")}`;
  };

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const result = await client.graphql({
          query: getPage,
          variables: { id },
        });
        const page = result.data.getPage;
        if (page) {
          setPageData(page);
        } else {
          setError("Page not found.");
        }
      } catch (err) {
        console.error("Error fetching page data:", err);
        setError("Error fetching page data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchPage();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setPageData((prevData) => ({
        ...prevData,
        title: value,
        url: slugify(value),
      }));
    } else {
      setPageData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleContentChange = (content) => {
    setPageData((prevData) => ({ ...prevData, content }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle cover image upload with compression
  const uploadCoverImage = async () => {
    if (file) {
      try {
        // Compression options
        const options = {
          maxSizeMB: 0.4, // Target maximum size of 400 KB
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const base64Image = await imageCompression.getDataUrlFromFile(
          compressedFile
        );

        return base64Image; // Return compressed image as base64
      } catch (error) {
        console.error("Error compressing cover image:", error);
        setError("Error compressing cover image. Please try again.");
        return null;
      }
    }
    return null;
  };

  const handleUpdatePage = async () => {
    if (!pageData.title || !pageData.content) {
      setError("Please fill out all fields.");
      setSuccess(false);
      return;
    }

    try {
      setLoading(true);

      // Step 1: Upload image if a new file is selected and get the URL
      let imageUrl = pageData.coverImage; // Use existing image URL if not uploading a new one
      if (file) {
        imageUrl = await uploadCoverImage();
        if (imageUrl) {
          setPageData((prevData) => ({ ...prevData, coverImage: imageUrl }));
        }
      }

      // Step 2: Update page data with the new or existing image URL
      const updatedPageData = {
        id: pageData.id,
        title: pageData.title,
        url: pageData.url,
        content: pageData.content,
        coverImage: imageUrl,
      };

      await client.graphql({
        query: updatePage,
        variables: { input: updatedPageData },
        authMode: "userPool",
      });
      setError("");
      setSuccess(true);
      setTimeout(() => {
        navigate("/my-admin");
      }, 2000);
    } catch (error) {
      console.error("Error updating page:", error);
      setError("Error updating page. Please try again.");
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="admin-page">
      <h1>Edit Page</h1>
      <div className="edit-page">
        {error && <p className="error-message">{error}</p>}
        {success && (
          <p className="success-message">Page updated successfully!</p>
        )}

        <input
          type="text"
          name="title"
          placeholder="Page Title"
          value={pageData.title}
          onChange={handleChange}
        />
        <input
          type="text"
          name="url"
          placeholder="Page URL"
          value={pageData.url}
          readOnly
        />

        {/* Cover Image Upload */}
        <input type="file" accept="image/*" onChange={handleFileChange} />
        {pageData.coverImage && (
          <img
            src={pageData.coverImage}
            alt="Cover"
            style={{ width: "200px", marginTop: "10px", display: "block" }}
          />
        )}

        <WysiwygEditor
          initialContent={pageData.content}
          onContentChange={handleContentChange}
        />

        <button onClick={handleUpdatePage} disabled={loading}>
          {loading ? "Updating..." : "Update Page"}
        </button>
      </div>
    </div>
  );
};

export default EditPage;
