/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNavItem = /* GraphQL */ `
  mutation CreateNavItem(
    $input: CreateNavItemInput!
    $condition: ModelNavItemConditionInput
  ) {
    createNavItem(input: $input, condition: $condition) {
      id
      name
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNavItem = /* GraphQL */ `
  mutation UpdateNavItem(
    $input: UpdateNavItemInput!
    $condition: ModelNavItemConditionInput
  ) {
    updateNavItem(input: $input, condition: $condition) {
      id
      name
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNavItem = /* GraphQL */ `
  mutation DeleteNavItem(
    $input: DeleteNavItemInput!
    $condition: ModelNavItemConditionInput
  ) {
    deleteNavItem(input: $input, condition: $condition) {
      id
      name
      url
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDraftPage = /* GraphQL */ `
  mutation CreateDraftPage(
    $input: CreateDraftPageInput!
    $condition: ModelDraftPageConditionInput
  ) {
    createDraftPage(input: $input, condition: $condition) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDraftPage = /* GraphQL */ `
  mutation UpdateDraftPage(
    $input: UpdateDraftPageInput!
    $condition: ModelDraftPageConditionInput
  ) {
    updateDraftPage(input: $input, condition: $condition) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDraftPage = /* GraphQL */ `
  mutation DeleteDraftPage(
    $input: DeleteDraftPageInput!
    $condition: ModelDraftPageConditionInput
  ) {
    deleteDraftPage(input: $input, condition: $condition) {
      id
      title
      url
      content
      coverImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
