import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { listPages } from "../../graphql/queries";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Blog.scss"; // Your blog styling

const Blog = () => {
  const client = generateClient();
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track errors

  useEffect(() => {
    fetchPages(); // Fetch pages when component mounts
  }, []);

  // Fetch pages from the API and sort by createdAt
  const fetchPages = async () => {
    try {
      const apiData = await client.graphql({ query: listPages });
      const sortedPages = apiData.data.listPages.items.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt) // Sort in descending order
      );
      setPages(sortedPages); // Set the sorted pages in state
    } catch (error) {
      console.error("Error fetching pages:", error);
      setError("Error fetching blog posts. Please try again.");
    } finally {
      setLoading(false); // Stop loading once the fetch is complete
    }
  };

  return (
    <div className="blog">
      <div className="container">
        <h1>Latest Blog Posts</h1>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : pages.length === 0 ? (
          <p>No blog posts available.</p>
        ) : (
          <div className="blog-list">
            {pages.map((page) => (
              <Link
                key={page.id}
                to={`/blog/${page.url.replace("/blog/", "")}`}
              >
                <div className="blog-item">
                  {/* Display blog title */}
                  <h2>{page.title}</h2>
                  {/* Display cover image if available */}
                  {page.coverImage && (
                    <img src={page.coverImage} alt={page.title} />
                  )}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
