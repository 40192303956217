// import React, { useEffect, useState } from "react";
import React, { useState } from "react";
import logo from "../../../src/logo.svg";
import "./TopNav.scss";
// import { Amplify } from "aws-amplify";
// import { generateClient } from "aws-amplify/api";
// import awsExports from "../../aws-exports";
// import { listNavItems } from "../../graphql/queries";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";

// Amplify.configure(awsExports); // Configure Amplify

const TopNav = () => {
  //   const [navItems, setNavItems] = useState([]);
  //     const client = generateClient();
  //     const client = generateClient({
  //       authMode: "apiKey", // Ensure we're using the correct authorization mode
  //     });

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  //   useEffect(() => {
  //     const fetchNavItems = async () => {
  //       try {
  //         const apiData = await client.graphql({
  //           query: listNavItems,
  //           authMode: "apiKey",
  //         });
  //         setNavItems(apiData.data.listNavItems.items);
  //       } catch (err) {
  //         console.error("Error fetching nav items:", err);
  //       }
  //     };

  //     fetchNavItems();
  //   }, []);

  return (
    <header className="top-nav">
      <div className="container">
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="Myoolade logo" />
          </div>
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          <span className={menuOpen ? "cross" : "hamburger"}></span>
        </div>
        <div className={`nav-links ${menuOpen ? "open" : ""}`}>
          <nav>
            <ul>
              {/* {navItems.map((item) => (
                <li key={item.id}>
                  {item.url.startsWith("/contact") ? (
                    // Using anchor tag directly for in-page navigation
                    <RouterLink
                      to={item.url}
                      className="contact"
                      onClick={toggleMenu}
                    >
                      {item.name}
                    </RouterLink>
                  ) : (
                    <RouterLink to={item.url} onClick={toggleMenu}>
                      {item.name}
                    </RouterLink>
                  )}
                </li>
              ))} */}
              <li>
                <RouterLink
                  to="/contact"
                  className="contact"
                  onClick={toggleMenu}
                >
                  Contact
                </RouterLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default TopNav;
