import React, { useEffect, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { listNavItems, listPages, listDraftPages } from "../graphql/queries"; // Import listDraftPages
import {
  createNavItem,
  deleteNavItem,
  updateNavItem,
  deletePage,
  deleteDraftPage, // Import deleteDraftPage mutation
} from "../graphql/mutations";
import "./Admin.scss";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Admin = () => {
  const client = generateClient();
  const [navItems, setNavItems] = useState([]);
  const [newItem, setNewItem] = useState({ name: "", url: "" });
  const [pages, setPages] = useState([]);
  const [draftPages, setDraftPages] = useState([]); // State for draft pages
  const navigate = useNavigate();

  useEffect(() => {
    fetchNavItems();
    fetchPages();
    fetchDraftPages(); // Fetch draft pages on load
  }, []);

  const fetchNavItems = async () => {
    try {
      const apiData = await client.graphql({ query: listNavItems });
      setNavItems(apiData.data.listNavItems.items);
    } catch (err) {
      console.error("Error fetching nav items:", err);
    }
  };

  const fetchPages = async () => {
    try {
      const apiData = await client.graphql({ query: listPages });
      setPages(apiData.data.listPages.items);
    } catch (err) {
      console.error("Error fetching pages:", err);
    }
  };

  const fetchDraftPages = async () => {
    try {
      const apiData = await client.graphql({ query: listDraftPages });
      setDraftPages(apiData.data.listDraftPages.items);
    } catch (err) {
      console.error("Error fetching draft pages:", err);
    }
  };

  const handleCreateItem = async () => {
    if (!newItem.name || !newItem.url) return;
    try {
      await client.graphql({
        query: createNavItem,
        variables: { input: newItem },
        authMode: "userPool",
      });
      setNewItem({ name: "", url: "" });
      fetchNavItems();
    } catch (err) {
      console.error("Error creating nav item:", err);
    }
  };

  const handleItemUpdate = async (id, newName, newUrl) => {
    try {
      const updatedItem = { id, name: newName, url: newUrl };
      await client.graphql({
        query: updateNavItem,
        variables: { input: updatedItem },
        authMode: "userPool",
      });
      fetchNavItems();
    } catch (err) {
      console.error("Error updating nav item:", err);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await client.graphql({
        query: deleteNavItem,
        variables: { input: { id } },
        authMode: "userPool",
      });
      fetchNavItems();
    } catch (err) {
      console.error("Error deleting nav item:", err);
    }
  };

  const handleDeletePage = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this page?"
    );
    if (confirmDelete) {
      try {
        await client.graphql({
          query: deletePage,
          variables: { input: { id } },
          authMode: "userPool",
        });
        fetchPages();
      } catch (err) {
        console.error("Error deleting page:", err);
      }
    }
  };

  const handleDeleteDraftPage = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this draft?"
    );
    if (confirmDelete) {
      try {
        await client.graphql({
          query: deleteDraftPage,
          variables: { input: { id } },
          authMode: "userPool",
        });
        fetchDraftPages(); // Refresh the list of draft pages after deletion
      } catch (err) {
        console.error("Error deleting draft page:", err);
      }
    }
  };

  return (
    <Authenticator>
      {({ signOut }) => (
        <div className="admin-page">
          <div className="container">
            <h1>Admin Panel</h1>
            <h2>Manage Navigation Items</h2>

            <div className="create-item">
              <input
                type="text"
                placeholder="Item Name"
                value={newItem.name}
                onChange={(e) =>
                  setNewItem({ ...newItem, name: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Item URL"
                value={newItem.url}
                onChange={(e) =>
                  setNewItem({ ...newItem, url: e.target.value })
                }
              />
              <button onClick={handleCreateItem}>Add Item</button>
            </div>

            <ul>
              {navItems.map((item) => (
                <li key={item.id}>
                  <input
                    type="text"
                    defaultValue={item.name}
                    onBlur={(e) =>
                      handleItemUpdate(item.id, e.target.value, item.url)
                    }
                  />
                  <input
                    type="text"
                    defaultValue={item.url}
                    onBlur={(e) =>
                      handleItemUpdate(item.id, item.name, e.target.value)
                    }
                  />
                  <button onClick={() => handleDeleteItem(item.id)}>
                    Delete
                  </button>
                </li>
              ))}
            </ul>

            <h2>Manage Blog Pages</h2>
            <button onClick={() => navigate("/my-admin/add-page")}>
              Add New Page
            </button>
            <button onClick={() => navigate("/my-admin/add-page-ai")}>
              Add New AI Page
            </button>

            <ul>
              {pages.map((page) => (
                <li key={page.id}>
                  {page.title} - {page.url}
                  <button
                    onClick={() => navigate(`/my-admin/edit-page/${page.id}`)}
                  >
                    Edit
                  </button>
                  <button onClick={() => handleDeletePage(page.id)}>
                    Delete
                  </button>
                  <a
                    href={`${window.location.origin}${
                      page.url.startsWith("/") ? "" : "/"
                    }${page.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button>View Page</button>
                  </a>
                </li>
              ))}
            </ul>

            <h2>Manage Draft Pages</h2>
            <ul>
              {draftPages.map((draft) => (
                <li key={draft.id}>
                  {draft.title} - {draft.url}
                  <button
                    onClick={() => navigate(`/my-admin/edit-draft/${draft.id}`)}
                  >
                    Edit
                  </button>
                  <button onClick={() => handleDeleteDraftPage(draft.id)}>
                    Delete
                  </button>
                </li>
              ))}
            </ul>

            <Link to="/my-admin/analytics">
              <button>Analytics</button>
            </Link>

            <button onClick={signOut}>Sign Out</button>
          </div>
        </div>
      )}
    </Authenticator>
  );
};

export default Admin;
