import React, { useState } from "react";

const TestComponent = () => {
  const [userPrompt, setUserPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Replace with your actual API Gateway URL for the Lambda function
  const apiUrl =
    "https://iyldttig13.execute-api.eu-west-2.amazonaws.com/addPageAiApi";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const res = await fetch(apiUrl, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({ prompt: userPrompt }), // Pass the prompt to the Lambda function
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.statusText}`);
      }

      const data = await res.json();
      setResponse(data.content); // Display the response in the component
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Test OpenAI Lambda Function</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          rows="4"
          cols="50"
          value={userPrompt}
          onChange={(e) => setUserPrompt(e.target.value)}
          placeholder="Type your question here"
          required
        ></textarea>
        <br />
        <button type="submit" disabled={loading}>
          {loading ? "Fetching response..." : "Submit"}
        </button>
      </form>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {response && (
        <div>
          <h3>OpenAI Response:</h3>
          <p>{response}</p>
        </div>
      )}
    </div>
  );
};

export default TestComponent;
