import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { getDraftPage } from "../graphql/queries";
import {
  updateDraftPage,
  createPage,
  deleteDraftPage,
} from "../graphql/mutations";
import { useParams, useNavigate } from "react-router-dom";
import WysiwygEditor from "../Components/WysiwygEditor/WysiwygEditor";
import "./Admin.scss";

const EditDraft = () => {
  const client = generateClient();
  const { id } = useParams();
  const navigate = useNavigate();

  const [draftData, setDraftData] = useState({
    id: "",
    title: "",
    url: "",
    content: "",
    coverImage: "", // Field for cover image
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const slugify = (title) => {
    return `/blog/${title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-")}`;
  };

  useEffect(() => {
    const fetchDraft = async () => {
      try {
        const result = await client.graphql({
          query: getDraftPage,
          variables: { id },
        });
        const draft = result.data.getDraftPage;
        if (draft) {
          setDraftData(draft);
        } else {
          setError("Draft not found.");
        }
      } catch (err) {
        console.error("Error fetching draft data:", err);
        setError("Error fetching draft data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchDraft();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setDraftData((prevData) => ({
        ...prevData,
        title: value,
        url: slugify(value),
      }));
    } else {
      setDraftData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleContentChange = (content) => {
    setDraftData((prevData) => ({ ...prevData, content }));
  };

  // Handle cover image file upload
  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDraftData((prevData) => ({
          ...prevData,
          coverImage: reader.result,
        }));
      };
      reader.readAsDataURL(file); // Convert file to base64 string
    }
  };

  const handleUpdateDraft = async () => {
    if (!draftData.title || !draftData.content) {
      setError("Please fill out all fields.");
      setSuccess("");
      return;
    }

    const updatedPageData = {
      id: draftData.id,
      title: draftData.title,
      url: draftData.url,
      content: draftData.content,
      coverImage: draftData.coverImage,
    };

    try {
      await client.graphql({
        query: updateDraftPage,
        variables: { input: updatedPageData },
        authMode: "userPool",
      });
      setError("");
      setSuccess("Draft updated successfully!");

      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error) {
      console.error("Error updating draft:", error);
      setError("Error updating draft. Please try again.");
      setSuccess("");
    }
  };

  const handlePublishPage = async () => {
    if (!draftData.title || !draftData.content) {
      setError("Please fill out all fields.");
      setSuccess(false);
      return;
    }

    try {
      await client.graphql({
        query: createPage,
        variables: {
          input: {
            title: draftData.title,
            url: draftData.url,
            content: draftData.content,
            coverImage: draftData.coverImage,
          },
        },
        authMode: "userPool",
      });

      await client.graphql({
        query: deleteDraftPage,
        variables: { input: { id: draftData.id } },
        authMode: "userPool",
      });

      setError("");
      setSuccess("Page published successfully.");
      setTimeout(() => {
        navigate("/my-admin");
      }, 2000);
    } catch (error) {
      console.error("Error publishing page:", error);
      setError("Error publishing page. Please try again.");
      setSuccess(false);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="admin-page">
      <h1>Edit Draft</h1>
      <div className="edit-page">
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        <input
          type="text"
          name="title"
          placeholder="Page Title"
          value={draftData.title}
          onChange={handleChange}
        />
        <input
          type="text"
          name="url"
          placeholder="Page URL"
          value={draftData.url}
          readOnly
        />

        {/* Cover Image Upload */}
        <input type="file" accept="image/*" onChange={handleCoverImageChange} />
        {draftData.coverImage && (
          <img
            src={draftData.coverImage}
            alt="Cover"
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}

        <WysiwygEditor
          initialContent={draftData.content}
          onContentChange={handleContentChange}
        />

        <button onClick={handleUpdateDraft}>Update Draft</button>
        <button onClick={handlePublishPage}>Publish Page</button>
      </div>
    </div>
  );
};

export default EditDraft;
