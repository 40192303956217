import React, { useEffect, useState } from "react";
import "./Analytics.scss";

const Analytics = () => {
  const [insights, setInsights] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl =
    "https://4qm13hndj5.execute-api.eu-west-2.amazonaws.com/fetchAnalyticsData";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Error fetching analytics insights");
        }
        const data = await response.json();
        const formattedInsights = data.insights;
        //   .replace(/\n/g, "<br />") // Replace newlines with <br />
        //   .replace(/### (.*?)(?=\n|$)/g, "<strong>$1</strong>") // Bold headings
        //   .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"); // Bold text

        setInsights(formattedInsights); // Set formatted insights
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="analytics">
      <div className="container">
        <h1>Analytics Insights</h1>
        <div
          className="analytics-content"
          dangerouslySetInnerHTML={{ __html: insights }}
        ></div>
        {/* <div className="analytics-content">{insights}</div> */}
      </div>
    </div>
  );
};

export default Analytics;
