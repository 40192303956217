// import React, { useEffect, useState } from "react";
import "./Footer.scss";
import sega from "../../Media/Images/logos/sega.png";
import netflix from "../../Media/Images/logos/netflix.png";
import puffin from "../../Media/Images/logos/puffin-books.png";
import bristolUni from "../../Media/Images/logos/bristol-university.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p className="designs-by">Design trusted by:</p>
        <div className="logos">
          <div className="logo">
            <img src={sega} alt="Sega logo" />
          </div>
          <div className="logo">
            <img src={netflix} alt="Netflix logo" />
          </div>
          <div className="logo">
            <img src={puffin} alt="Puffin Books logo" />
          </div>
          <div className="logo">
            <img src={bristolUni} alt="Bristol University logo" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
